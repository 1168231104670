import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import styles from './gcpServiceManagement.module.scss'
import { createTheme } from 'react-data-table-component';
import { GET_SERVICES, GET_CUSTOMERS_LIST, GCP_SERVICE_MANAGEMENT_ACCOUNT_DETAILS, GCP_SUBSCRIPTION_TABLE, GCP_CHANGE_STATUS, GCP_LISTING_ROLES_EMAILS, GCP_ROLES, GCP_SAVE_EDIT_ROLES, GCP_STATUS_TYPE } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Form from "react-bootstrap/Form";
import { Container, Button, Row, Col, Dropdown, Table, OverlayTrigger, Tooltip, Accordion, Card, useAccordionButton, AccordionContext } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import serviceLogo from '../../../../../Assets/Images/Illustrations/gcp.svg';
import SearchDropdown from "../../../../../GlobalComponents/SearchDropdown";
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import GCPFilterBox from './LocalComponents/FilterBox';
import EmptyServiceSubscription from '../../../../../Assets/Images/Illustrations/EmptyServiceSubscription.svg';
import EmptyServiceProvision from '../../../../../Assets/Images/Illustrations/EmptyServiceProvision.svg';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif";
import Toast from '../../../../../GlobalComponents/Toast';
import NoCustomer from '../../../../../Assets/Images/Illustrations/noCustomer.svg'
import failureImg from '../../../../../Assets/Images/Illustrations/failure.svg';
import DeleteCardImage from '../../../../../Assets/Images/Illustrations/delete-card-img.svg';
import LazyTable from './LocalComponents/LazyTable';

const GCPServiceManagement = ({ setOpen, setSelectService, setSelectCategory, Logout }) => {

    const location = useLocation();                                                 // use to access data from other page data
    const navigate = useNavigate();                                                 // to use navigate function from react router dom
    const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Manage") ? true : false);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                    // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(null);                 // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(true);                            // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                      // showing filter box
    const [status, setStatus] = useState(["ACTIVE","SUSPENDED"]);                                     // getTableData() status
    const searchInputRef = useRef();                                                // Search Input Box

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                        // table active page number
    const [pageSize, setPageSize] = useState(15);                                    // table active page size
    const [totalRows, setTotalRows] = useState(0);                                  // table total count of data from api 
    const [columns, setColumns] = useState([]);                                     // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["Plan", "Sub-account Name", "Sub-account ID",
        "Linked Projects", "Billing Term", "Status", "Actions"];                // table headers used to make object of react data table component 
    let ColumnNameReadOnly = ["Plan", "Sub-account Name", "Sub-account ID",
        "Linked Projects", "Billing Term", "Status"];
    const [serverErrorUser, setServerErrorUser] = useState(false);                   // server error check state in table during api call to load data
    const [emptyUser, setEmptyUser] = useState(false);                               // empty table check state in table during api call to load data
    const [emptyUserSearch, setEmptyUserSearch] = useState(false);                   // empty search response check state in table during api call to load data
    const [emptyUserFilters, setEmptyUserFilters] = useState(true);                 // empty filter response check state in table during api call to load data
    const [statusLastUsed, setStatusLastUsed] = useState("default");                 // status dropdown saved data after applying filters  
    const [customerId, setCustomerId] =
        useState(location.state !== null ? location.state.customerId : "");   // storing customer id 
    const [serviceId, setServiceId] = useState(null);                                  // storing service names for dropdown
    const [serviceData, setServiceData] = useState(null);                            // data in service dropdown
    const [customerData, setCustomerData] = useState([]);                            // data in status dropdown
    const [modalOpen, setModalOpen] = useState(false);                               // opens modal popup
    const [additionalDetails, setAdditionalDetails] = useState(null);                // storing additionaldetails for popup from api
    const [initialSubscriptionTableCall, setInitialSubscriptionTableCall] =
        useState(null);                                                                  // initial loading of subscription table if service is provisioned
    const [loading, setLoading] = useState(false);                                   // loading state while api is called for table , also for search and filter   
    const [provisionRefreshIconFlag, setProvisionRefreshIconFlag] = useState(false); // refresh icon if service is provisioned on table
    const [filterApplied, setFilterApplied] = useState(false);                        // filter in gettabledata

    // Search Value for Service Name
    const [serviceNameDefaultValue, setServiceNameDefaultValue] = useState("Select Service Name");
    const [serviceNameSearchValue, setServiceNameSearchValue] = useState();
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameFiltered, setServiceNameFiltered] = useState(serviceName);
    const updatedServiceName = [];

    // Search Value for Customer Name
    const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState("Select Customer Name");
    const [customerNameSearchValue, setCustomerNameSearchValue] = useState();
    const [customerName, setCustomerName] = useState([]);
    const [customerNameFiltered, setCustomerNameFiltered] = useState(customerName);
    const updatedCustomerName = [];

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    const [poNumber, setPoNumber] = useState("");                               // Order Confirmation PO Number
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);        // Processing after calling api
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);                      // Terms and condition checkbox popup

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);
    const [infinityLoading, setInfinityLoading] = useState(false);                     // loading state while table loads for lazy loading

    // Selected Row for Actions
    const [selectedRowActivate, setSelectedRowActivate] = useState(null)                        // Selected row from subscription table to open in activate option
    const [selectedRowDeactivate, setSelectedRowDeactivate] = useState(null)                        // Selected row from subscription table to open in deactivate status option
    let changeStatusConfirmationColumnName = ["Plan", "Sub-account Name", "Sub-account ID", "Billing Term"]
    const [actionCompletedRefreshSubscriptionTable, setActionCompletedRefreshSubscriptionTable] = useState(false)
    const [deactiveStatusModalFlag, setDeactiveStatusModalFlag]                 // deactive popup
        = useState(null);
    const [activeStatusModalFlag, setActiveStatusModalFlag]                     // activate popup
        = useState(null);
    const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/; // RegEx for PO Number
    const [copied, setCopied] = useState("Click to copy");                          // Copy Use State
    const [roleManageModalFlag, setRoleManageModalFlag]                     // activate popup
        = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [addUserTabFlag, setAddUserTabFlag] = useState(false);
    const [addUserEmailErrorFlag, setAddUserEmailErrorFlag] = useState(false);
    const [addUserEmailMaxErrorFlag, setAddUserEmailMaxErrorFlag] = useState(false);
    const [addUserEmailErrorFlagMessage, setAddUserEmailErrorFlagMessage] = useState(null);
    const [roleManagementData, setRoleManagementData] = useState(null);
    const [emailArrayData, setEmailArrayData] = useState([]);
    const [rolesRemovedArrayData, setRolesRemovedArrayData] = useState([]);

    const [emptyRoles, setEmptyRoles] = useState(false);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [emailId, setEmailId] = useState("");                     // updating email to null 
    const [rolesData, setRolesData] = useState(null);                     // roles data 
    const [roleChosen, setRoleChosen] = useState(null);                     // roles data 
    const [roleChosenEditState, setRoleChosenEditState] = useState([]);                     // roles data 
    const [defaultAllSelectedRoles, setDefaultAllSelectedRoles] = useState([]);

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [errorFlag, setErrorFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDefault, setIsDefault] = useState(false);
    const [billingAccountId, setBillingAccountId] = useState(null);
    const [confirmloadingFlagRoleManagement, setConfirmloadingFlagRoleManagement] = useState(false);                                   // loading state while api is called for table , also for search and filter   
    const [editAllowed, setEditAllowed] = useState(false);                                   // loading state while api is called for table , also for search and filter   
    const [isDefaultEditState, setIsDefaultEditState] = useState(false);                                   // loading state while api is called for table , also for search and filter   
    const [statusData, setStatusData] = useState(null);                               // data in status dropdown

    const defaultServiceName = "Google Cloud";
    const defaultCategoryName = "Cloud Sales Enquiry";

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    // Copied to Clipboard Function
    const copyToClipboard = (domainName) => {
        navigator.clipboard.writeText(domainName);
        setCopied("Copied!");
        setTimeout(() => {
            setCopied("Click to copy");
        }, 1900);
    };

    // Function to call all dropdown values in Service Section 
    async function GetAllServices() {

        await api.get(GET_SERVICES, config)
            .then(resp => {
                let serviceArray = []
                resp.data.map((service) => {
                    if (service.serviceType == "ONLINE") {
                        serviceArray.push(service);
                    }
                })
                setServiceData(serviceArray);
                setServiceNameFiltered(serviceArray);
                for (let i in resp.data) {
                    if (resp.data[i].integrationCode === "GCP") {
                        setServiceId(resp.data[i].id);
                        setServiceNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }
                for (let i in resp.data) {
                    if (!serviceName.includes(resp.data[i].name)) {
                        serviceName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Customer Section 
    async function GetAllCustomers() {
        await api.get(GET_CUSTOMERS_LIST, config)
            .then(resp => {
                setCustomerData(resp.data);
                setCustomerNameFiltered(resp.data);

                for (let i in resp.data) {
                    if (resp.data[i].id === customerId) {
                        setCustomerNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }

                for (let i in resp.data) {
                    if (!customerName.includes(resp.data[i].name)) {
                        customerName.push(resp.data[i].name);
                    }
                }

            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // function to call all additional details ie. popup of GCP
    async function GetAdditionalDetails() {
        await api.get(`${GCP_SERVICE_MANAGEMENT_ACCOUNT_DETAILS}?customerId=${customerId}`, config)
            .then(resp => {
                setAdditionalDetails(resp.data);
                if (resp.data.isProvision) {
                    setInitialSubscriptionTableCall(true);
                    setProvisionRefreshIconFlag(false);
                }
                else {
                    setInitialSubscriptionTableCall(false);
                    setProvisionRefreshIconFlag(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setInitialSubscriptionTableCall(false);
                }

            });
    };

    // Function to call all dropdown values in status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(GCP_STATUS_TYPE, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // accordion
    function CustomToggleAccordion({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = activeEventKey === eventKey;
        return (
            <span
                className='pt-1 align-content-center'
                onClick={decoratedOnClick}
            >{children}
                {isCurrentEventKey ? (
                    <span className='down-accordion-gcp' />
                ) : (
                    <span className='right-accordion' />
                )}
            </span>
        );
    }
    // Get Table Data with Pagination and Filter and Search Functionality
    async function GetTableData(page, newPerPage, search, status, appendData, isFilter) {
        setLoading(true);
        (search === undefined || search === null) ? search = "" : search = search;
        (status === undefined || status === null) ? status = "" : status = status;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        await api.get(`${GCP_SUBSCRIPTION_TABLE}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&customerId=${customerId}`, config)
            .then(resp => {
                setLoading(false);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    if(filterFlag){
                        isFilter = filterFlag
                        isFilter = search != "" ? !isFilter : isFilter; 
                    }
                    if (search === "" && (status === "" || status.length === 0 )) {
                        setShowFilterBox(false);
                        setEmptyUser(true);
                    }
                    else if (status != "" && isFilter) {
                        setEmptyUserFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyUserSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorUser(false);
                    setEmptyUser(false);
                    setEmptyUserSearch(false);
                    setEmptyUserFilters(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Plan": td.planName,
                                    "Sub-account Name": td.subAccountName,
                                    "Sub-account ID": ((td.vendorSubscriptionId).replace(/^billingAccounts\//i, '')),
                                    "Linked Projects": <a target='_blank' href={`https://console.cloud.google.com/billing/${(td.vendorSubscriptionId).replace(/^billingAccounts\//i, '')}/manage`}><span className={styles["gcp-color-underline"]}>{td.linkedProjects}</span></a>,
                                    "Billing Term": "Pay As You Go",
                                    "Status": td.status === 'ACTIVE' ? (
                                        <div className={`${styles['status-gcp']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) : td.status === 'CANCELLED' ? (
                                        <div className={`${styles['status-gcp']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Cancelled</div>
                                        </div>
                                    ) : td.status === 'PENDING' ? (
                                        <div className={`${styles['status-gcp']}`}>
                                            <span className="status-pending"></span>
                                            <div className="py-1 text-muted text-small">Pending</div>
                                        </div>
                                    ) : td.status === 'ERROR' ? (
                                        <div className={`${styles['status-gcp']}`}>
                                            <span className="status-failed"></span>
                                            <div className="py-1 text-muted text-small">Error</div>
                                        </div>
                                    ) : td.status === 'DELETED' ? (
                                        <div className={`${styles['status-gcp']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Deleted</div>
                                        </div>
                                    ) : td.status === 'SUSPENDED' ? (
                                        <div className={`${styles['status-gcp']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Suspended</div>
                                        </div>
                                    ) : td.status === 'PENDING_USER_ACTION' ? (
                                        <div className={`${styles['status-gcp']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Pending User Action</div>
                                        </div>
                                    ) : (
                                        td.status
                                    ),
                                    "Actions": (<>
                                        {td.status != "PENDING" ? <Dropdown drop={"start"}>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                {td.status === 'ACTIVE' ? (
                                                    <>
                                                        <Dropdown.Item onClick={(e) => { setSelectedRowDeactivate(td); setDeactiveStatusModalFlag(true); }} id="1">Suspend</Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => { setSelectedRow(td); setRoleManageModalFlag(true); setBillingAccountId(td.vendorSubscriptionId); GetListingEmailsRoles(td.vendorSubscriptionId); }} id="1">Manage Subaccount Permissions</Dropdown.Item>
                                                        <Dropdown.Item id="2" className={`${styles['gcp-link-actions']}`} href={`https://console.cloud.google.com/billing/${(td.vendorSubscriptionId).replace(/^billingAccounts\//i, '')}`} target="_blank">Manage Billing</Dropdown.Item>
                                                        <Dropdown.Item id="3" className={`${styles['gcp-link-actions']}`} href={`https://console.cloud.google.com/billing/${(td.vendorSubscriptionId).replace(/^billingAccounts\//i, '')}/budgets`} target="_blank">Manage Budget & Alerts</Dropdown.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Dropdown.Item onClick={(e) => { setSelectedRowActivate(td); setActiveStatusModalFlag(true); }} id="1">Activate</Dropdown.Item>                
                                                        <Dropdown.Item id="2" className={`${styles['gcp-link-actions']}`} href={`https://console.cloud.google.com/billing/${td.vendorSubscriptionId}`} target="_blank">Manage Billing</Dropdown.Item>
                                                        <Dropdown.Item id="3" className={`${styles['gcp-link-actions']}`} href={`https://console.cloud.google.com/billing/${td.vendorSubscriptionId}/budgets`} target="_blank">Manage Budget & Alerts</Dropdown.Item>
                                                    </>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown> : <span className={`${styles['threedots']} three-dots-icon-disabled`} />}</>
                                    ),
                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    (isreadOnly ? ColumnNameReadOnly : ColumnName).map((td) => {
                        if (td === "Actions") {
                            d.push({
                                id: `${td}`,
                                name: <div className='d-none-mobile'>{td}</div>,
                                selector: row => <div className="text-wrap d-none-mobile">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Status" || td === "Linked Projects") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Plan") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                                allowOverflow: true,

                            })
                        }
                        else if (td === "Sub-account Name") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 3,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Billing Term") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Sub-account ID") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1.5,
                                allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data

                }
            })
            .catch(error => {
                setLoading(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorUser(true);
                }
            });
    };

    // get api for addition mapping data
    async function GetListingEmailsRoles(billingAccountId) {
        setConfirmloadingFlagRoleManagement(true);
        (billingAccountId === undefined || billingAccountId === null) ? billingAccountId = "" : billingAccountId = billingAccountId;
        await api.get(`${GCP_LISTING_ROLES_EMAILS}?billingAccountId=${billingAccountId}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setConfirmloadingFlagRoleManagement(false);
                    setEmptyRoles(true);
                }

                else {
                    setConfirmloadingFlagRoleManagement(false);
                    setEmptyRoles(false);
                    setRoleManagementData(resp.data.policyDetailsList);
                }

            })
            .catch(error => {
                setConfirmloadingFlagRoleManagement(false);
                setEmptyRoles(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // get api for roles data
    async function GetRoles() {
        await api.get(`${GCP_ROLES}`, config)
            .then(resp => {
                setRolesData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, status, true))();


            return newPageNumber;
        });
    }
    // On search field value we trigger this function having react debugging after every 2000 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
            },
        },
    };

    // Activate Subscription
    async function ActivateSubscription() {
        setConfirmLoadingFlag(true);

        await api.post(GCP_CHANGE_STATUS + parseInt(selectedRowActivate.subscriptionId) + "/activate", null, config)
            .then(resp => {
                if (resp.status = 200) {
                    setActiveStatusModalFlag(false);
                    setConfirmLoadingFlag(false);

                    setToastMessage("Subscription activated successfully.");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setSelectedRowActivate(null);
                    setActionCompletedRefreshSubscriptionTable(true);
                }
            })
            .catch(error => {
                setConfirmLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setToastMessage("We encountered an issue while activating the subscription. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
            })
    }


    // Activate Subscription
    async function DeactivateSubscription() {
        setConfirmLoadingFlag(true);

        await api.post(GCP_CHANGE_STATUS + parseInt(selectedRowDeactivate.subscriptionId) + "/suspend", null, config)
            .then(resp => {
                if (resp.status = 200) {
                    setDeactiveStatusModalFlag(false);
                    setConfirmLoadingFlag(false);

                    setToastMessage("Subscription suspended successfully.");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setSelectedRowDeactivate(null);
                    setActionCompletedRefreshSubscriptionTable(true);
                }
            })
            .catch(error => {
                setConfirmLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setToastMessage("We encountered an issue while suspending the subscription. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
            })
    }

    // save edit email roles
    async function SaveEditEmailRoles() {
        setConfirmloadingFlagRoleManagement(true);
        let requestBody = {
            "billingAccountId": billingAccountId,
            "roles": selectedRoles.join(','),
            "emails": selectedRoles.length > 0 ? emailArrayData.map(email => "user:" + email).join(',') : "",
            "removedUserRoles": {
                "roles": editAllowed ? rolesRemovedArrayData.join(',') : "",
                "email": (rolesRemovedArrayData.length > 0 && editAllowed) ? emailArrayData.map(email => "user:" + email).join(',') : "",
            }
        };
        await api.post(GCP_SAVE_EDIT_ROLES, requestBody, config)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmloadingFlagRoleManagement(false);
                    setActiveAccordion(null);
                    setRoleChosen(null);
                    setRoleChosenEditState(null);
                    setIsDefault(false);
                    setIsDefaultEditState(false);
                    setDefaultAllSelectedRoles([]);
                    setSelectedRoles([]);
                    setRolesRemovedArrayData([]);
                    setEmailArrayData([]);
                    setEmailId("");
                    setErrorFlag(false);
                    setEmptyRoles(null);
                    setRoleManagementData(null);
                    setAddUserTabFlag(false);
                    setEditAllowed(false);
                    GetListingEmailsRoles(billingAccountId);
                    setToastMessage("User roles have been saved successfully.");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setAddUserTabFlag(false);
                setConfirmloadingFlagRoleManagement(false);
                setErrorFlag(true);
                setErrorMessage(error.response.data.errors);
            })
    }

    // Refreshing Subscription Table on Action Completion
    useEffect(() => {
        if (actionCompletedRefreshSubscriptionTable) {
            GetAdditionalDetails();
            GetTableData(pageNo, pageSize, searchValueTable, status);
            setActionCompletedRefreshSubscriptionTable(false);
        }
    }, [actionCompletedRefreshSubscriptionTable])

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, status);
    };

    // useeffect for calling dropdown data and table data 
    useEffect(() => {
        GetAdditionalDetails();
        GetAllServices();
        GetAllCustomers();
    }, [customerId]);

    // Function to trigger getTable data function if service is provisioned
    useEffect(() => {
        if (initialSubscriptionTableCall) {
            setPageNo(1);
            if (filterApplied)
                GetTableData(1, pageSize, searchValueTable, status, false, true);
            else
                GetTableData(1, pageSize, searchValueTable, status, false, false);
        }
    }, [initialSubscriptionTableCall]);

    const AddEmailLocally = (emailId) => {
        if (emailArrayData.length < 5) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const isEmail = emailRegex.test(emailId);
            if (isEmail) {
                if (emailArrayData.length > 0 && emailArrayData.includes(emailId)) {
                    setAddUserEmailErrorFlagMessage("Duplicate email is not allowed");
                    setAddUserEmailErrorFlag(true);
                }
                else {
                    setEmailArrayData(prev => [...prev, emailId]);
                    setAddUserEmailErrorFlagMessage("");
                    setEmailId("");
                }
            }
            else {
                setAddUserEmailErrorFlagMessage("Please enter a valid email")
                setAddUserEmailErrorFlag(true);
            }
        }
        else {
            setAddUserEmailMaxErrorFlag(true);
            setAddUserEmailErrorFlagMessage("");
        }
    }

    // for editing button control
    const arraysAreEqual = () => {
        if (selectedRoles.length !== defaultAllSelectedRoles.length) {
            return false;
        }
        const result = selectedRoles.map(item => defaultAllSelectedRoles.includes(item));
        if (result.includes(false)) {
            return false;
        }
        return result;
    };

    //  checkboxes for roles selection 
    const HandleCheckboxChange = (roleNameValue) => {
        // when editing is allowed
        if (editAllowed) {
            if (selectedRoles.includes(roleNameValue)) {
                // Role is currently selected, uncheck it
                setSelectedRoles(selectedRoles.filter(role => role !== roleNameValue));
                setRolesRemovedArrayData([...rolesRemovedArrayData, roleNameValue]);
            } else {
                // Role is not selected, check it
                setSelectedRoles([...selectedRoles, roleNameValue]);
                setRolesRemovedArrayData(rolesRemovedArrayData.filter(role => role !== roleNameValue));
            }
        }
        // when editing is not allowed and add is allowed
        else {
            // Check if the role is already selected
            if (selectedRoles.includes(roleNameValue)) {
                // If selected, remove it from the array
                setSelectedRoles(selectedRoles.filter(role => role !== roleNameValue));
            } else {
                // If not selected, add it to the array
                setSelectedRoles([...selectedRoles, roleNameValue]);
            }
        }
    };

    // Delete Email from member list
    const EmailListDelete = (e) => {
        const emailNewDeletedArray = emailArrayData.filter(
            (item) => item !== (e.target.id)
        );
        setEmailArrayData(emailNewDeletedArray);
    };

    // useEffect when service ID is changed
    useEffect(() => {
        if (serviceId) {
            let url = "";
            let integrationCode = "";
            serviceData && serviceData.map((data) => {
                if (data.id == serviceId) {
                    integrationCode = data.integrationCode
                }
            })
            switch (integrationCode) {
                case "softlayer": // IBM Cloud
                    navigate("/management-console/ibm", { state: { customerId: customerId } });
                    break;

                case "microsoftazure": // Microsoft CSP - Azure
                    navigate("/management-console/azure", { state: { customerId: customerId } });
                    break;

                case "cloudcare": // Avast Cloud Care
                    navigate("/management-console/avast", { state: { customerId: customerId } });
                    break;

                case "acronis1": //  Acronis Cyber Cloud
                    navigate("/management-console/acronis", { state: { customerId: customerId } });
                    break;

                case "googleworkspace": // Google Workspace
                    navigate("/management-console/gws", { state: { customerId: customerId } });
                    break;

                case "GCP":  // Google Cloud Platform
                    navigate("/management-console/gcp", { state: { customerId: customerId } });
                    break;

                default: // Microsoft CSP
                    navigate("/management-console/csp", { state: { customerId: customerId } });
                    break;
            }
        }

    }, [serviceId]);

    //Scroll to top on opening of page
    useEffect(() => {
        window.scrollTo(0, 0);
        GetRoles();
        GetAllStatuses();
    }, [])


    //Scroll to top on opening of page
    useEffect(() => {
        if (isDefault)
            rolesData.map((role) => {
                if (roleChosen === role.roleName) {
                    setSelectedRoles([...selectedRoles, role.roleNameValue])
                }
            })
        if (isDefaultEditState) {
            rolesData.map((role) => {
                if (roleChosenEditState === role.roleName) {
                    setEmailArrayData(prev => [...prev, emailId]);
                }
            })
        }
    }, [isDefault, isDefaultEditState])

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    // Function to compare two objects for equality
    function areObjectsEqual(obj1, obj2) {
        // Get the keys of both objects
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // If the number of keys is different, objects are not equal
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Check if all keys and their values are equal
        for (const key of keys1) {
            // If the value of the key is an object, recursively check equality
            if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                if (!areObjectsEqual(obj1[key], obj2[key])) {
                    return false;
                }
            } else {
                // Otherwise, compare values directly
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }
        }

        // If all keys and values are equal, objects are equal
        return true;
    }

    return (
        <div className='main-content'>
            <div className='container-fluid'>
                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

                <Popup
                    className="custom-modal custom-modal-xl"
                    open={deactiveStatusModalFlag}
                    onClose={() => { setDeactiveStatusModalFlag(false); setSelectedRowDeactivate(null); setPoNumber(""); }}
                    closeOnDocumentClick={!confirmLoadingFlag}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">

                            <div className="header">
                                <h2>Order Confirmation - Suspend Subscription
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip className={`${styles['gcp-change-status-tooltip']}`}>Note: After issuing a suspension, customers can't create new resources on the suspended account. Customers can still manage existing resources while a suspension is active & all existing resources are still billable.</Tooltip>}
                                    >
                                        <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                    </OverlayTrigger>
                                </h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                                />
                            </div>
                            <Container fluid>
                                <Row className="pb-3 pt-5">
                                    <Col lg={8}>
                                        <h3 className="mb-3">Customer Name: <span className="text-regular">{additionalDetails && additionalDetails.customerName}</span></h3>
                                    </Col>
                                    <Col lg={4}>
                                        <h3 className="mb-3">Cloud Service: <span className="text-regular">Google Cloud</span></h3>
                                    </Col>
                                </Row>
                                <Row>
                                    {selectedRowDeactivate &&
                                        <Table className={`${styles['gws-trial-to-paid-core-table']}`} responsive="md">
                                            <thead className="sticky-top">
                                                <tr>
                                                    {changeStatusConfirmationColumnName.map(th => {
                                                        return (<th>{th}</th>)
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedRowDeactivate &&
                                                    <tr>
                                                        <td>{selectedRowDeactivate.planName}</td>
                                                        <td>{selectedRowDeactivate.subAccountName}</td>
                                                        <td>{selectedRowDeactivate.vendorSubscriptionId}</td>
                                                        <td>Pay As You Go</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                </Row>
                                <Row className="py-3">
                                    <Col>

                                        <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="w-auto d-inline ml-3"
                                            id="inputPONumber"
                                            placeholder='Add PO Number'
                                            aria-describedby="inputPONumber"
                                            maxlength="50"
                                            value={poNumber}
                                            onChange={(e) => {
                                                alphanumericRegex.test(e.target.value)
                                                    ? setPoNumber(e.target.value)
                                                    : console.log("")
                                            }}
                                        />
                                        <Form.Text id="poNumber" />

                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-4">
                                    <Col md={6}>

                                        <Form.Check // prettier-ignore
                                            type="checkbox"
                                            id="termsCheckBox"
                                            label=""
                                            disabled
                                            checked={isTermsChecked}
                                            className="d-inline-block w-auto"
                                        />
                                        <Form.Label className="d-inline-block  w-auto mb-0">
                                            I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                        </Form.Label>

                                    </Col>
                                    <Col md={6}>
                                        <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                            {!confirmLoadingFlag && isTermsChecked && <>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { close() }}>Cancel</Button>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => DeactivateSubscription()}>
                                                    Confirm
                                                </Button>
                                            </>
                                            }
                                            {!confirmLoadingFlag && !isTermsChecked &&
                                                <>
                                                    <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => { setDeactiveStatusModalFlag(false); }}>Cancel
                                                    </Button>
                                                    <Button
                                                        variant="light"
                                                        className="px-4 mx-2"
                                                        disabled
                                                    >
                                                        Confirm
                                                    </Button>
                                                </>
                                            }
                                            {confirmLoadingFlag &&
                                                <>
                                                    <Button
                                                        variant="light"
                                                        disabled
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                    >Cancel
                                                    </Button>
                                                    <Button
                                                        disabled
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                    >
                                                        Processing . . .
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )}
                </Popup>
                <Popup
                    className="custom-modal custom-modal-xl"
                    open={activeStatusModalFlag}
                    onClose={() => { setActiveStatusModalFlag(false); setSelectedRowActivate(null); setPoNumber(""); }}
                    closeOnDocumentClick={!confirmLoadingFlag}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">

                            <div className="header">
                                <h2>Order Confirmation - Activate Subscription</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                                />
                            </div>
                            <Container fluid>
                                <Row className="pb-3 pt-5">
                                    <Col lg={8}>
                                        <h3 className="mb-3">Customer Name: <span className="text-regular">{additionalDetails && additionalDetails.customerName}</span></h3>
                                    </Col>
                                    <Col lg={4}>
                                        <h3 className="mb-3">Cloud Service: <span className="text-regular">Google Cloud</span></h3>
                                    </Col>
                                </Row>
                                <Row>
                                    {selectedRowActivate &&
                                        <Table className={`${styles['gws-trial-to-paid-core-table']}`} responsive="md">
                                            <thead className="sticky-top">
                                                <tr>
                                                    {changeStatusConfirmationColumnName.map(th => {
                                                        return (<th>{th}</th>)
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedRowActivate &&
                                                    <tr>
                                                        <td>{selectedRowActivate.planName}</td>
                                                        <td>{selectedRowActivate.subAccountName}</td>
                                                        <td>{selectedRowActivate.vendorSubscriptionId}</td>
                                                        <td>Pay As You Go</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                </Row>
                                <Row className="py-3">
                                    <Col>

                                        <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="w-auto d-inline ml-3"
                                            id="inputPONumber"
                                            placeholder='Add PO Number'
                                            aria-describedby="inputPONumber"
                                            maxlength="50"
                                            value={poNumber}
                                            onChange={(e) => {
                                                alphanumericRegex.test(e.target.value)
                                                    ? setPoNumber(e.target.value)
                                                    : console.log("")
                                            }}
                                        />
                                        <Form.Text id="poNumber" />

                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-4">
                                    <Col md={6}>

                                        <Form.Check // prettier-ignore
                                            type="checkbox"
                                            id="termsCheckBox"
                                            label=""
                                            disabled
                                            checked={isTermsChecked}
                                            className="d-inline-block w-auto"
                                        />
                                        <Form.Label className="d-inline-block  w-auto mb-0">
                                            I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                        </Form.Label>

                                    </Col>
                                    <Col md={6}>
                                        <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                            {!confirmLoadingFlag && isTermsChecked && <>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { close(); }}>Cancel</Button>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => ActivateSubscription()}>
                                                    Confirm
                                                </Button>
                                            </>
                                            }
                                            {!confirmLoadingFlag && !isTermsChecked &&
                                                <>
                                                    <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => { setActiveStatusModalFlag(false); }}>Cancel
                                                    </Button>
                                                    <Button
                                                        variant="light"
                                                        className="px-4 mx-2"
                                                        disabled
                                                    >
                                                        Confirm
                                                    </Button>
                                                </>
                                            }
                                            {confirmLoadingFlag &&
                                                <>
                                                    <Button
                                                        variant="light"
                                                        disabled
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                    >Cancel
                                                    </Button>
                                                    <Button
                                                        disabled
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                    >
                                                        Processing . . .
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )}
                </Popup>
                <Popup
                    open={modalOpen}
                    onClose={() => { setModalOpen(false); }}
                    className="custom-modal custom-modal-md custom-modal-ticket-details"
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header">
                                <h2>Cloud Portal Details</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={close}
                                />
                            </div>
                            <Container>

                                {additionalDetails && <Row>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Cloud Service</label>
                                        <h3 className="mb-3">Google Cloud</h3>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Customer</label>
                                        <p><strong>{additionalDetails.customerName}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Start Date</label>
                                        <p><strong>{additionalDetails.startDate}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Cloud Identity Id</label>
                                        <p><strong>{additionalDetails.cloudIdentityId}</strong></p>
                                    </Col>
                                    <Col md={12} className="mb-2">
                                        <label className="text-medium">Domain Name</label>
                                        <p className='d-flex align-items-center'>
                                            <span className="text-strong long-text">{additionalDetails.domainName}</span>
                                            <span className="clicktocopy">
                                                <span
                                                    type="button"
                                                    class="copy cpointer ml-2"
                                                    onClick={() => copyToClipboard(additionalDetails.domainName)}
                                                >
                                                    &nbsp;
                                                </span>
                                                <span className="copystatus">{copied}</span>
                                            </span>
                                        </p>

                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">End Relationship</label>
                                        <p><a className='text-strong text-underline cpointer' onClick={() => { setSelectService(defaultServiceName); setSelectCategory(defaultCategoryName); close(); setOpen(true); }}>Cancel Entitlement <span className='external-link cpointer ml-2'></span></a>
                                        </p>
                                    </Col>

                                </Row>}
                            </Container>
                        </div>
                    )}
                </Popup>

                {/* Sub account permission */}
                <Popup
                    className={!errorFlag ? "custom-modal custom-modal-xl" : "custom-modal custom-modal-sm"}
                    open={roleManageModalFlag}
                    onClose={() => { setRoleManageModalFlag(false); setSelectedRowActivate(null); setAddUserEmailMaxErrorFlag(false); setRoleManagementData(null); setAddUserTabFlag(false); setEmailId(""); setAddUserEmailMaxErrorFlag(false); setActiveAccordion(null); setRoleChosen(null); setRoleChosenEditState(null); setSelectedRoles([]); setEmailArrayData([]); setErrorFlag(false); setEditAllowed(false); setDefaultAllSelectedRoles([]); setEmptyRoles(false); }}
                    closeOnDocumentClick={!confirmLoadingFlag}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">

                            <div className="header">
                                <h2>{errorFlag ? (errorMessage[0]!=="Permissions are already in place" ? "Error" : "") : "Sub Account Permission"}</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                                />
                            </div>

                            {/* error message popup */}
                            {errorFlag &&
                                <>
                                    <Row className="pt-5">
                                        <Col md={12} className="text-center my-4">
                                            <img src={errorMessage[0]!=="Permissions are already in place" ? failureImg  : DeleteCardImage} alt="Add User Failure" />
                                        </Col>
                                    </Row>
                                    <Container fluid >
                                        {errorMessage[0]!=="Permissions are already in place" && <p className='text-center text-strong'>Something went wrong</p>}
                                        <p className='text-center'>{errorMessage}</p>
                                        <div className="btn-wrapper text-center mb-6">
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={() => { setErrorFlag(false); setAddUserTabFlag(true); }}>
                                                Ok
                                            </Button>
                                        </div>
                                    </Container>
                                </>
                            }
                            {!errorFlag && <Container className={`pt-0 pb-0 ${styles['fixed-height-width-xl']}`}>
                                {!addUserTabFlag && !errorFlag &&
                                    <Container>
                                        <Row className="pb-1 pt-5">
                                            <Col lg={6} md={6}>
                                                <h3 className="mb-3">Plan Name: <span className="text-regular">{selectedRow && selectedRow.planName}</span></h3>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <h3 className="mb-3">Subscription ID: <span className="text-regular">{selectedRow && (selectedRow.subscriptionId)}</span></h3>
                                            </Col>
                                        </Row>

                                        {/* loading state */}
                                        {roleManagementData === null && !emptyRoles && <div className={styles["loading-gif"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p></div>}

                                        {/* role and email listing   */}
                                        {roleManagementData &&
                                            <>
                                                <Row className="my-1">
                                                    <Col md={9} className={`mt-4`}>
                                                        <h3>Roles & Users</h3>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="btn-wrapper text-right d-md-block d-none my-2">
                                                            <Button
                                                                className="btn btn-lg px-4 mx-2"
                                                                onClick={() => { setAddUserTabFlag(true); }}>Add User</Button>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className={`${styles['fixed-height']}`}>
                                                    {roleManagementData && roleManagementData.map((roles, index) => (
                                                        <Accordion key={index} activeKey={activeAccordion} onSelect={(eventKey) => setActiveAccordion(eventKey === activeAccordion ? null : eventKey)} className='mx-1 my-1'>
                                                            <Card className={`py-1 ${styles['accordion-downgradeGWS']}`}>
                                                                <Row className={` ${activeAccordion === `accordion-${index}` ? styles['active-accordion'] : styles['accordion-heading']}`}>
                                                                    <Col className='mx-4 my-1 justify-content-between py-1'>
                                                                        <CustomToggleAccordion className='text-left text-center' eventKey={`accordion-${index}`}></CustomToggleAccordion>
                                                                        <span className='text-regular px-3'>{roles.role}</span>
                                                                    </Col>
                                                                </Row>
                                                                <Accordion.Collapse eventKey={`accordion-${index}`}>
                                                                    <div style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}>
                                                                        <Row className="mt-2">
                                                                            <Col className='mx-11'>
                                                                                <Button
                                                                                    onClick={() => { setAddUserTabFlag(true); setRoleChosen(roles.role); setIsDefault(true); }}
                                                                                    variant="light"
                                                                                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12 col-sm-auto d-flex align-items-start">
                                                                                    <span className={`flex-grow-1 mt-1 ${styles['add-user-color']}`}>Add User</span>
                                                                                    <span className='ml-2 plus-icon-add-user'></span>
                                                                                </Button>
                                                                                <hr className='mx-1 mb-0 mt-2'></hr>
                                                                            </Col>
                                                                        </Row>
                                                                        {(roles.members).map((emails) => (
                                                                            <Row key={emails}>
                                                                                <Col className='mx-12'>
                                                                                    <Row>
                                                                                        <Col md={4}><span>{emails.replace(/^user:/i, '')}</span>
                                                                                        </Col>
                                                                                        <Col md={1}> <span className='quantity-active ml-3'
                                                                                            onClick={() => {
                                                                                                roleManagementData.map((roles, index) => {
                                                                                                    if (roles.members.includes(emails)) {
                                                                                                        defaultAllSelectedRoles.push(roles.roleNameValue);
                                                                                                        setRoleChosenEditState(roles.role);
                                                                                                    }
                                                                                                })
                                                                                                setSelectedRoles(defaultAllSelectedRoles);
                                                                                                // console.log("map for all checked:", defaultAllSelectedRoles, JSON.stringify(defaultAllSelectedRoles), JSON.stringify(selectedRoles), JSON.stringify(defaultAllSelectedRoles) != JSON.stringify(selectedRoles));
                                                                                                setEmailId(emails.replace(/^user:/i, ''));
                                                                                                setEditAllowed(true); setIsDefaultEditState(true); setAddUserTabFlag(true);
                                                                                            }}></span></Col> </Row>
                                                                                    <hr className='mb-0 mt-2'></hr>
                                                                                </Col>
                                                                            </Row>
                                                                        ))}
                                                                    </div>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </Accordion>
                                                    ))}
                                                </div>

                                            </>
                                        }
                                        {/* when 204 comes in api ie. no roles listed */}
                                        {
                                            roleManagementData === null && emptyRoles &&
                                            <div className={`${styles["role-management-no-data-customers"]} mt-5 mb-5`}>
                                                <img src={NoCustomer} className="empty-customers-table-svg" />
                                                <h2 className="mt-4 mb-3">No users assigned to the role</h2>
                                                <Button className="btn btn-lg btn-primary px-3" onClick={() => { setAddUserTabFlag(true); }}>Add User</Button>
                                            </div>
                                        }

                                        <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                            {!emptyRoles && <>
                                                {roleManagementData === null ?
                                                    <Button
                                                        variant="light"
                                                        disabled
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                    >Cancel
                                                    </Button>
                                                    :
                                                    <Button
                                                        variant="light"
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                        onClick={() => { close(); }}>Cancel
                                                    </Button>
                                                }</>}
                                            {emptyRoles && <Button
                                                variant="light"
                                                className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                onClick={() => { close(); }}>Cancel
                                            </Button>}
                                        </div>

                                    </Container>}

                                {/* add user details */}
                                {addUserTabFlag && !errorFlag &&
                                    <Container fluid>
                                        {editAllowed ? <h2 className='mt-4 mb-2'>Edit User Role</h2> : <h2 className='mt-4 mb-2'>Add User</h2>}
                                        {!editAllowed ? <p>Enter one or more members (email address, group, workload identity pool, or workforce pool). Then, select a role to grant them access to the billing sub-account. You can assign multiple roles.</p> : <p></p>}
                                        <Col md={6}>
                                            <Form onSubmit={(e) => e.preventDefault()}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Email<span className='red'>*</span></Form.Label>
                                                    {!editAllowed ? <span className={!addUserEmailMaxErrorFlag ? `text-medium pt-1 float-right pl-2 ${styles['max-email-error']}` : `text-small pl-2 text-danger pt-1 float-right `}>Max 5</span> : <span></span>}
                                                    <Form.Control type="text" className={addUserEmailErrorFlag ? "border-danger" : ""}
                                                        placeholder="Enter email ID"
                                                        value={emailId}
                                                        onChange={(e) => { setEmailId(e.target.value); }}
                                                        readOnly={confirmloadingFlagRoleManagement}
                                                        disabled={editAllowed}
                                                        onKeyDown={(e) => {
                                                            setAddUserEmailErrorFlag(false);
                                                            setEmailId(e.target.value);
                                                            if (e.key == "Enter") {
                                                                AddEmailLocally(e.target.value);
                                                            }

                                                        }} />
                                                    {addUserEmailErrorFlag && <span className={`${styles['error-block-height']} text-danger text-medium`}>{addUserEmailErrorFlagMessage}</span>}
                                                    {!addUserEmailErrorFlag && <span className={`${styles['error-block-height']} text-danger text-medium`}></span>}

                                                </Form.Group>
                                                {!editAllowed && <div className={`${styles['email-array']}`}>
                                                    {
                                                        emailArrayData.length > 0 && emailArrayData.map((email, index) => {
                                                            return (
                                                                <Col md={5}>
                                                                    <label key={index}>
                                                                        <span>
                                                                            {email.length < 14 ? email : email.slice(0, 11) + "..."}
                                                                        </span>
                                                                        <XLg id={email} className="mx-3 remove-member cpointer float-right" onClick={EmailListDelete} />
                                                                    </label>
                                                                </Col>
                                                            );
                                                        })
                                                    }
                                                </div>}
                                            </Form>

                                        </Col>
                                        <h2 className='mt-4 mb-2'>Roles<span className='red'>*</span></h2>
                                        <div className={`${styles['checkbox-div']}`}>

                                            {!editAllowed ?
                                                rolesData.map((user) => {
                                                    return (
                                                        <Form.Check
                                                            key={user.roleNameValue} // Ensure each checkbox has a unique key
                                                            type="checkbox"
                                                            className='mr-4 cpointer'
                                                            id={`default-${user.roleNameValue}`}
                                                            label={user.roleName}
                                                            disabled={(roleChosen !== null) ? true : false}
                                                            onChange={() => {
                                                                HandleCheckboxChange(user.roleNameValue);
                                                            }
                                                            }
                                                            defaultChecked={(user.roleName === roleChosen) ? true : selectedRoles.includes(user.roleNameValue)}
                                                        />
                                                    );
                                                }) :
                                                rolesData.map((user) => {
                                                    return (
                                                        <Form.Check
                                                            key={user.roleNameValue}      // Ensure each checkbox has a unique key
                                                            type="checkbox"
                                                            className='mr-4 cpointer'
                                                            id={`default-${user.roleNameValue}`}
                                                            disabled={confirmloadingFlagRoleManagement}
                                                            label={user.roleName}
                                                            onChange={() => {
                                                                HandleCheckboxChange(user.roleNameValue);
                                                            }
                                                            }
                                                            defaultChecked={(isDefaultEditState && user.roleName === roleChosenEditState) || defaultAllSelectedRoles.includes(user.roleNameValue)}
                                                        />
                                                    );
                                                })
                                            }
                                        </div>

                                        <div className="btn-wrapper text-right d-md-block d-none mb-7 mt-4">
                                            {!confirmloadingFlagRoleManagement ?
                                                <>
                                                    <Button
                                                        className="btn btn-lg px-4 mx-2 btn-border-light"
                                                        variant="light"
                                                        onClick={() => { setAddUserTabFlag(false); setEmailArrayData([]); setRoleChosen(null); setRolesRemovedArrayData([]); setSelectedRoles([]); setIsDefault(false); setIsDefaultEditState(false); setEditAllowed(false); setAddUserEmailMaxErrorFlag(false); setEmailId(""); setDefaultAllSelectedRoles([]); }}>
                                                        Back</Button>
                                                    {!editAllowed ?
                                                        ((emailArrayData.length > 0 && selectedRoles.length > 0) ?
                                                            <Button
                                                                className="px-4 mx-2"
                                                                onClick={() => {
                                                                    SaveEditEmailRoles();
                                                                }}
                                                            >
                                                                Save
                                                            </Button> :
                                                            <Button
                                                                className="btn btn-lg px-4 mx-2 btn-border-light"
                                                                variant="light"
                                                                disabled
                                                            >
                                                                Save
                                                            </Button>) :

                                                        (arraysAreEqual() ? <Button
                                                            className="btn btn-lg px-4 mx-2 btn-border-light"
                                                            variant="light"
                                                            disabled
                                                        >
                                                            Save
                                                        </Button>
                                                            :
                                                            <Button
                                                                className="px-4 mx-2"
                                                                onClick={() => {
                                                                    SaveEditEmailRoles();
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        )}
                                                </> :
                                                <>
                                                    <Button
                                                        variant="light"
                                                        disabled
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                    >Back
                                                    </Button>
                                                    <Button
                                                        disabled
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                    >
                                                        Processing . . .
                                                    </Button>
                                                </>
                                            }
                                        </div>

                                    </Container>}
                            </Container>}
                        </div>
                    )}
                </Popup>
                <Row>
                    <Col md={6} className="justify-content-between d-flex align-items-center">
                        <label className="cpointer" onClick={() => navigate('/management-console')}>
                            <span className="back">&nbsp;</span>&nbsp;<strong><span className={`${styles['service-management-heading']}`}>Google Cloud-Service Management</span></strong>
                        </label>
                    </Col>
                    <Col md={6} className="text-right service-logo">
                        <img className={`${styles['gcp-service-management-logo']}`} src={serviceLogo} />
                    </Col>
                </Row>
                <div className='service-management-azure-header mt-5'>
                    <Row className='mb-3'>
                        <Col md={3} className='mb-3'>
                            <SearchDropdown
                                dropdownLabel="Cloud Service"
                                formGroupId="serviceNameId"
                                placeholder="Enter Service Name"
                                selectDefaultValue={serviceNameDefaultValue}
                                setSelectDefaultValue={setServiceNameDefaultValue}
                                selectOptions={serviceData}
                                selectOptionsFiltered={serviceNameFiltered}
                                setSelectOptionsFiltered={setServiceNameFiltered}
                                selectSearchValue={serviceNameSearchValue}
                                setSelectSearchValue={setServiceNameSearchValue}
                                updatedOptions={updatedServiceName}
                                setOptionId={setServiceId}
                                setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                            />
                        </Col>
                        <Col md={3} className='mb-3'>
                            <SearchDropdown
                                dropdownLabel="Customer Name"
                                formGroupId="customerNameId"
                                placeholder="Enter Customer Name"
                                selectDefaultValue={customerNameDefaultValue}
                                setSelectDefaultValue={setCustomerNameDefaultValue}
                                setOptionId={setCustomerId}
                                selectOptions={customerData}
                                selectOptionsFiltered={customerNameFiltered}
                                setSelectOptionsFiltered={setCustomerNameFiltered}
                                selectSearchValue={customerNameSearchValue}
                                setSelectSearchValue={setCustomerNameSearchValue}
                                updatedOptions={updatedCustomerName}
                                setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                            />
                        </Col>
                        <Col md={6} className='text-right'>
                            <div class="d-flex justify-content-between justify-content-md-end align-items-center h-100">
                                <label class="pt-0 label-sm mr-3">Billing Sub-Accounts
                                    <span class="circle-bg label-sm">
                                        <strong class="label-sm">
                                            {additionalDetails ? additionalDetails.subscriptionLicenseCount : 0}
                                        </strong>
                                    </span>
                                </label>

                                {initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="light" onClick={() => setModalOpen(true)}>Cloud Portal Details</Button>}
                                {!initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="secondary" disabled >Cloud Portal Details</Button>}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="service-management-azure-body user-management-console-main-div mb-5">
                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">
                            <Col className={`p-0 ${styles['user-management-table']}`}>
                                <div className={`${styles['user-management-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                            <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Billing Sub-Accounts {!emptyUser && !emptyUserFilters && !emptyUserSearch && !loading && initialSubscriptionTableCall && <span className='text-muted pl-1 text-large'>({totalRows})</span>}{!loading && !provisionRefreshIconFlag && initialSubscriptionTableCall && <span className='refresh ml-2' onClick={() => { setScrollToDivTop(true); GetAdditionalDetails(); setPageNo(1); GetTableData(1, pageSize, searchValueTable, status) }}></span>}</h5>
                                        </Col>
                                        <Col className="my-1 d-md-none d-block text-right mr-1">
                                            <Button onClick={() => navigate("/cloud-provision", {

                                                state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }

                                            })}>Buy Sub-account</Button>                                        </Col>

                                        {/* Filter and Search Box for Desktop and Tablet */}
                                        <Col md={8} className="d-none d-md-block">
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorUser && !emptyUser && initialSubscriptionTableCall &&
                                                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative pr-4">
                                                        {!loading && !searchIconFlag &&
                                                            <span className="mx-3">&nbsp;</span>
                                                        }
                                                        {!loading && !searchIconFlag && !emptyUserFilters &&
                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                        }
                                                        {!loading && searchIconFlag && !emptyUserFilters &&
                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search for Sub-account Name/Sub-account ID"
                                                                    aria-label="Search for Sub-account Name/Sub-account ID"
                                                                    aria-describedby="basic-addon2"
                                                                    className="search-input-box-input"
                                                                    value={searchValueTable}
                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                    ref={searchInputRef}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setSearchValueTable("");
                                                                        setSearchIconFlag(!searchIconFlag);
                                                                    }}>
                                                                    <span className="closebtn">
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        }
                                                        {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}></span>}
                                                        {!loading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                        <Button className="ml-3 px-4" onClick={() => navigate("/cloud-provision", {

                                                            state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }

                                                        })}>Buy Sub-accounts</Button>


                                                    </Col>}
                                            </Row>
                                        </Col>


                                        {/* Filter and Search Box for Mobile */}
                                        <Col className="d-md-none d-block mt-2 px-3" md={12}>
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorUser && !emptyUser && initialSubscriptionTableCall && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">

                                                    <InputGroup className="search-input-box">
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                            <span className="searchgrey cpointer"
                                                            >
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Search for Sub-account Name/Sub-account ID"
                                                            aria-label="Search for Sub-account Name/Sub-account ID"
                                                            aria-describedby="basic-addon2"
                                                            className="search-input-box-input"
                                                            value={searchValueTable}
                                                            onChange={(e) => setSearchValueTable(e.target.value)}
                                                            ref={searchInputRef}
                                                        />
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                            onClick={() => {
                                                                setSearchValueTable("");
                                                                setSearchIconFlag(!searchIconFlag);
                                                            }}>
                                                            <span className="closebtn">
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                    </InputGroup>

                                                    {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}
                                                    {!loading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}


                                                </Col>}
                                            </Row>
                                        </Col>
                                    </div>
                                    {
                                        !emptyUserSearch && showFilterBox &&
                                        <GCPFilterBox GetTableData={GetTableData} pageNo={pageNo} setPageNo={setPageNo} setEmptyUserFilters={setEmptyUserFilters}
                                            pageSize={pageSize} searchValueTable={searchValueTable} setShowFilterBox={setShowFilterBox}
                                            setFilterFlag={setFilterFlag} status={status} setStatus={setStatus} statusData={statusData}
                                            setScrollToDivTop={setScrollToDivTop} setFilterApplied={setFilterApplied}
                                        />
                                    }
                                    <>
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['gcp-service-table']}`}>
                                                    {!loading && serverErrorUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                        </div>
                                                    }
                                                    {!loading && emptyUserSearch && !emptyUserFilters && initialSubscriptionTableCall && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Matching Subscriptions Found.</h2>
                                                        </div>
                                                    }
                                                    {!initialSubscriptionTableCall && initialSubscriptionTableCall != null && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyServiceProvision} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">Selected Cloud Service is not provisioned</h2>
                                                            <p className="mb-3">No subscriptions are currently available for this cloud service.</p>
                                                            <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                                                navigate("/cloud-provision", {

                                                                    state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                                })
                                                            }}>Provision Cloud Service</Button>                                                        </div>
                                                    }
                                                    {!loading && emptyUserFilters && initialSubscriptionTableCall && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                        </div>
                                                    }
                                                    {!loading && emptyUser && initialSubscriptionTableCall &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyServiceSubscription} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Subscriptions in this Cloud Service.</h2>
                                                            <p className="mb-3">Please click on Buy Subscriptions to Add Subscriptions</p>
                                                            <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                                                navigate("/cloud-provision", {
                                                                    state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                                })
                                                            }}>Buy Subscription</Button>                                             
                                                                       </div>
                                                    }
                                                    {!serverErrorUser && !emptyUser && !emptyUserFilters && !emptyUserSearch && initialSubscriptionTableCall &&
                                                        <LazyTable
                                                            d={columns}
                                                            table={table}
                                                            customStyles={customStyles}
                                                            loading={infinityLoading}
                                                            pageNo={pageNo}
                                                            totalRows={totalRows}
                                                            handlePerRowsChange={handlePerRowsChange}
                                                            handlePageChange={handlePageChange}
                                                            styles={styles}
                                                            handleLazyCalls={handleLazyCalls}
                                                            initialSubscriptionTableCall={initialSubscriptionTableCall}
                                                            customerNameDefaultValue={customerNameDefaultValue}
                                                            GetAdditionalDetails={GetAdditionalDetails}
                                                            serverErrorUser={serverErrorUser}
                                                            emptyUser={emptyUser}
                                                            emptyUserFilters={emptyUserFilters}
                                                            emptyUserSearch={emptyUserSearch}
                                                            setScrollToDivTop={setScrollToDivTop}
                                                            scrollToDivTop={scrollToDivTop}
                                                        />
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GCPServiceManagement;