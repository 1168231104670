import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Nav, NavDropdown, OverlayTrigger, Row, Tab, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './summary.module.scss'
import { CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_DOWNLOAD_WIDGET_REPORT, CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_WIDGET, CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_WIDGET_DETAILS, GET_CUSTOMERS_LIST, SUBSCRIPTION_OVERVIEW_STATUS } from '../../../../../../../../../Utils/GlobalConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme } from 'react-data-table-component';
import EmptyDataTableFilterSearch from '../../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import SummaryFilterBox from './LocalComponents/FilterBox';
import { BarChart } from '@mui/x-charts';
import Toast from '../../../../../../../../../GlobalComponents/Toast';
import { api } from '../../../../../../../../../Utils/API';
import LazyTable from './LocalComponents/LazyTable';

const Summary = ({ setSummaryPopup, summaryPopup, tabName, setTabName, selectedItem2, setSelectedItem2, selectedItem3, setSelectedItem3, serviceId, setServiceId, setIntegrationCode, integrationCode, customerCode, Logout, serviceData }) => {

    const location = useLocation();
    const date = new Date();                                                    // for js date time function
    const navigate = useNavigate();                                             // to use navigate function from react router dom    

    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(searchParams.get('searchValueTable') || null);         // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                         // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                   // showing filter box
    const searchInputRef = useRef();
    const [status, setStatus] = useState(null);                                  // getTableData() Status 
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [planName, setPlanName] = useState(null);                                    // storing plan name for filter
    const [sortCol, setSortCol] = useState("sortStartDate");                                    // sort column name
    const [sortDir, setSortDir] = useState("DESC");                                    // sort column direction
    const [isDescStartDate, setIsDescStartDate] = useState(true);               // sort state check for start date column
    const [statusData, setStatusData] = useState(null);                             // data in status dropdown

    const [pageSize, setPageSize] = useState(15);                                // table active page size
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["Service",                              // table headers used to make object of react data table component headers
        "Plan", "Billing Term", "Quantity", "Start Date", "Auto Renew", "Status", "Change Status"];
    let ColumnNameNoAction = ["Service", "Plan", "Billing Term", "Quantity", "Start Date", "Auto Renew", "Status"]; // table headers used to make object of react data table component headers
    const [initialLoading, setInitialLoading] = useState(true);                 // loader for table
    const [emptyCustomerFilters, setEmptyCustomerFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCustomerSearch, setEmptyCustomerSearch] = useState(false);      // empty search response check state in table during api call to load data
    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data
    const [statusLastUsed, setStatusLastUsed] = useState("default");            // status dropdown saved data after applying filters  
    const [customerData, setCustomerData] = useState(null);                         // data in customer dropdown
    const [customerName, setCustomerName] = useState(location.state?.customerName ? location.state?.customerName : "");

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    const [barChartData, setBarChartData] = useState([
        { data: [0, 0, 0], label: 'New subscriptions' },
        { data: [0, 0, 0], label: 'Cancelled subscriptions' },
    ])
    const [barChartDataSeries, setBarChartDataSeries] = useState([])            // for x-axisdata in api

    const [isDisabledManage, setIsDisabledManage] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Manage") ? true : false);
    const [isReadManage, setIsReadManage] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Manage") ? true : false);

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading
    const [tableFilterApplied, setTableFilterApplied] = useState(false);                // table filter is apllied or not

    // Function to call all Customer data
    async function GetAllSubscriptionRenewalCount() {
        await api.get(`${CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_WIDGET}?duration=${selectedItem2}&integrationCode=${integrationCode}&serviceId=${serviceId}&customerCode=${customerCode}`, config)
            .then(resp => {
                setBarChartData(resp.data.data);
                setBarChartDataSeries(resp.data.xAxisData);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(GET_CUSTOMERS_LIST, config)
            .then(resp => {
                setCustomerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?serviceId=${serviceId}&duration=${selectedItem2}&integrationCode=${integrationCode}&customerCode=${customerCode}`, configuration)
            .then(resp => {
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get Table Data with Filters, Pagination, Search  Functionality
    async function GetTableData(page, newPerPage, search, status, sortCol, sortDir, serviceId, customerCode, isFilter, appendData) {
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (status === undefined || status === null || status === "null") ? status = "" : status = status;
        (sortCol === undefined || sortCol === null || sortCol === "null") ? sortCol = "" : sortCol = sortCol;
        (sortDir === undefined || sortDir === null || sortDir === "null") ? sortDir = "" : sortDir = sortDir;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        await api.get(`${CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_WIDGET_DETAILS}?duration=${selectedItem2}&integrationCode=${integrationCode}&serviceId=${serviceId}&customerCode=${customerCode}&pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&sortCol=${sortCol}&sortDir=${sortDir}`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    if (search === "" && status === "") {
                        setEmptyCustomer(true);
                    }
                    else if (status != "" && isFilter) {
                        setEmptyCustomerFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyCustomerSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorCustomer(false);
                    setEmptyCustomer(false);
                    setEmptyCustomerFilters(false);
                    setEmptyCustomerSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Customer": td.customerName,
                                    "Service": td.serviceName,
                                    "Plan": <>{td.planName}</>,
                                    "Billing Term": td.billingTerm,
                                    "Quantity": td.quantity,
                                    "Start Date": td.startDate,
                                    "Auto Renew": td.autoRenew,
                                    "Status": td.status === 'ACTIVE' ? (
                                        <div className='text-center'>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-small text-muted">Active</div>
                                        </div>
                                    ) : td.status === 'CANCELLED' ? (
                                        <div className='text-center'>
                                            <span className="status-cancelled"></span>
                                            <div className="py-1 text-small text-muted">Cancelled</div>
                                        </div>
                                    ) : td.status === 'DELETED' ? (
                                        <div className='text-center'>
                                            <span className="status-deleted"></span>
                                            <div className="py-1 text-small text-muted">Deleted</div>
                                        </div>
                                    ) : td.status === 'SUSPENDED' ? (
                                        <div className='text-center'>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Suspended</div>
                                        </div>
                                    ) : (
                                        td.status
                                    ),
                                    "Change Status": (td.isTrail || td.status == "CANCELLED" || td.status == "DELETED") ? <span className='dashboard-change-status-disabled'></span>
                                        : <span className='dashboard-change-status cpointer'
                                            onClick={() => {
                                                // console.log(td.customerCode);
                                                if (td.serviceName == "Google Workspace") {
                                                    if (td.isAddOnPlan) {
                                                        navigate("/management-console/gws", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.basePlanName } })
                                                    }
                                                    else {
                                                        navigate("/management-console/gws", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.planName } })
                                                    }
                                                }
                                                else {
                                                    if (td.isAddOnPlan) {
                                                        navigate("/management-console/csp", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.basePlanName } })
                                                    }
                                                    else {
                                                        navigate("/management-console/csp", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.planName } })
                                                    }
                                                }
                                            }}></span>

                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ((!isDisabledManage && !isReadManage) ? ColumnName : ColumnNameNoAction).map((td) => {
                        if (td === "Status" || td === "Auto Renew" || td === "Change Status") {
                            d.push({
                                id: `${td}`,
                                name: <div className='text-center'>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                            })
                        }
                        else if (td === "Start Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescStartDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); }}></span></>}
                                    {isDescStartDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescStartDate(false); setSortCol("sortStartDate"); }}></span></>}
                                    {isDescStartDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Billing Term") {
                            d.push({
                                id: `${td}`,
                                name:<> 
                                {integrationCode != "microsoftsaas" ? 
                                <div>{td}<OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                >
                                    <span className="infoIcon-support-table"></span>
                                </OverlayTrigger>
                                </div> : 
                                <div>Commitment Term (Billing Frequency)</div>}
                                </>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Quantity") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);       // Setting Column Data
                }
            })
            .catch(error => {
                setInitialLoading(true);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorCustomer(true);
                }
            });
    };

    // function called whenever service is changed from dropdown
    function GetAllServices(selectedServiceName) {
        serviceData.map((service) => {
            if (service?.name === selectedServiceName) {
                setServiceId(service.id);
                setIntegrationCode(service.integrationCode);
                setSelectedItem3(selectedServiceName);
            }
        })
    }

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(SUBSCRIPTION_OVERVIEW_STATUS, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status, sortCol, sortDir, serviceId, customerCode);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, pageNo) => {
        setPageNo(pageNo);
        setPageSize(newPerPage);
        GetTableData(pageNo, newPerPage, searchValueTable, status, sortCol, sortDir, serviceId, customerCode);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, serviceId, customerCode);
    }, [isDescStartDate])

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
            },
        },
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, status, sortCol, sortDir, serviceId, customerCode, false, true))();
            return newPageNumber;
        });
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setTableFilterApplied(false);
                    GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, serviceId, customerCode);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, serviceId, customerCode);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // Function to trigger getTable data function 
    useEffect(() => {
        GetAllServices();
        GetAllCustomers();
        GetAllStatuses();
    }, [])

    // Define a function to handle selecting a service from the dropdown
    const handleSelectService = (selectedServiceName) => {
        GetAllServices(selectedServiceName);
    };

    useEffect(() => {
        GetAllSubscriptionRenewalCount();
        setShowFilterBox(false);
        setScrollToDivTop(true);
        setPageNo(1);
        if (tableFilterApplied)
            GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, serviceId, customerCode, true);
        else
            GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, serviceId, customerCode, false);

    }, [selectedItem2, integrationCode, serviceId, selectedItem3]);

    // function for dropdown data
    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
    };

    //on tab change make scroll to top true
    useEffect(() => {
        setScrollToDivTop(true);
        setPageNo(1);
    }, [tabName])

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={summaryPopup}
                onClose={() => { setSummaryPopup(false); setServerErrorCustomer(false); setEmptyCustomer(false); setEmptyCustomerFilters(false); setEmptyCustomerSearch(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Subscriptions Overview</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <>
                            <div className='my-4 height-dashboard-charts'>
                                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

                                <Container fluid>
                                    <Tab.Container id="main-tab-bar" defaultActiveKey="Summary">
                                        <Tab.Container activeKey={tabName}>
                                            <Row>
                                                <Col md={9}>
                                                    <Nav variant="pills" defaultActiveKey={null}>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Summary" className='dashboard-tabs  pl-6 pr-6 pt-3 pb-3'
                                                                onClick={() => { setTabName("Summary") }}
                                                            >
                                                                Summary</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Details" className={'dashboard-tabs pl-6 pr-6 pt-3 pb-3 d-none-mobile'}
                                                                onClick={() => { setTabName("Details") }}
                                                            >
                                                                Details</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col md={3}> <div className='text-right'>
                                                    <Button
                                                        variant="light"
                                                        className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
                                                        onClick={(e) => { DownloadAction(CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_DOWNLOAD_WIDGET_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerName}_subscriptions-overview.csv`, "csv") }}
                                                    >
                                                        Download Report
                                                    </Button>
                                                </div></Col>

                                            </Row>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Summary">
                                                    <div>
                                                        <Row>
                                                            <Col md={8}></Col>
                                                            <Col md={4}>
                                                                <Row className={`pt-2 ${styles["widget-header"]} `}>
                                                                    <Col md={6}>
                                                                        <Form.Group>
                                                                            <NavDropdown title={selectedItem2} id="cloud-service-dropdown-2" className={`servcies-dropdown dropdown-specialclass ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setSelectedItem2)}>
                                                                                <NavDropdown.Item eventKey="Last 3 Months"
                                                                                    className={selectedItem2 === "Last 3 Months" ? "selected" : ""}
                                                                                >Last 3 Months</NavDropdown.Item>
                                                                                <NavDropdown.Item eventKey="Last 6 Months"
                                                                                    className={selectedItem2 === "Last 6 Months" ? "selected" : ""}
                                                                                >Last 6 Months</NavDropdown.Item>
                                                                                <NavDropdown.Item eventKey="Last 1 Year"
                                                                                    className={selectedItem2 === "Last 1 Year" ? "selected" : ""}
                                                                                >Last 1 Year</NavDropdown.Item></NavDropdown>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group>
                                                                            <NavDropdown title={selectedItem3} id="cloud-service-dropdown-3" className={`servcies-dropdown dropdown-specialclass-fortwo ${styles['dropdown-disabled']}`} onSelect={handleSelectService}>
                                                                                <NavDropdown.Item eventKey="Microsoft CSP"
                                                                                    className={selectedItem3 === "Microsoft CSP" ? "selected" : ""}
                                                                                >Microsoft CSP</NavDropdown.Item>
                                                                                <NavDropdown.Item eventKey="Google Workspace"
                                                                                    className={selectedItem3 === "Google Workspace" ? "selected" : ""}
                                                                                >Google Workspace</NavDropdown.Item> </NavDropdown>
                                                                        </Form.Group>
                                                                    </Col>

                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <div className={`${styles['bar-chart-height']}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            {barChartData && <BarChart
                                                                series={barChartData}
                                                                grid={{ horizontal: true, vertical: true }}
                                                                slotProps={{
                                                                    legend: {
                                                                        direction: 'row',
                                                                        position: { vertical: 'bottom', horizontal: 'middle' },
                                                                        padding: 0,
                                                                        paddingBottom: 20,
                                                                        labelStyle: {
                                                                            fill: '#6a6a6a'
                                                                        }
                                                                    },
                                                                    popper: {
                                                                        sx: {
                                                                            zIndex: 99999,
                                                                        },
                                                                    },
                                                                }}
                                                                colors={['#3599DA', '#F9A932']}
                                                                xAxis={[{
                                                                    data: barChartDataSeries,
                                                                    scaleType: 'band',
                                                                    tickPlacement: 'middle',
                                                                    tickLabelPlacement: 'middle',
                                                                }]}
                                                                yAxis={[{
                                                                    tickNumber: 5,
                                                                    tickMinStep: 1,
                                                                    // valueFormatter: (value) => `$ ${(value / 1000).toLocaleString()}K`,
                                                                    tickLabelInterval: 1
                                                                }]}
                                                                // loading
                                                                margin={{ top: 20, bottom: 100, left: 100, right: 100 }}
                                                            />}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Details">
                                                    <div className='my-4 position-relative'>
                                                        <Row >
                                                            <Col md={3}>
                                                                <h2 className='mx-7'>Subscriptions {!emptyCustomer && !emptyCustomerFilters && !emptyCustomerSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h2>
                                                            </Col>
                                                            <Col className="px-1 d-none d-md-block mb-2" lg={5} md={3}>
                                                                <Row className={`justify-content-end align-items-center mobile-padding-search-open-sub  ${styles['search-tablet-view']}`}>
                                                                    {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                        {!searchIconFlag &&
                                                                            <span className="mx-3">&nbsp;</span>
                                                                        }
                                                                        {!searchIconFlag && !emptyCustomerFilters &&
                                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                        }
                                                                        {searchIconFlag && !emptyCustomerFilters &&
                                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                    <span className="searchgrey cpointer"
                                                                                    >
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                                <Form.Control
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    aria-describedby="basic-addon2"
                                                                                    className="search-input-box-input"
                                                                                    value={searchValueTable}
                                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                    ref={searchInputRef}
                                                                                />
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                    onClick={() => {
                                                                                        setSearchValueTable("");
                                                                                        setSearchIconFlag(!searchIconFlag);
                                                                                    }}>
                                                                                    <span className="closebtn">
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        }

                                                                        {!filterFlag && !emptyCustomerSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                            setShowFilterBox(!showFilterBox);
                                                                        }}></span>}
                                                                        {initialLoading && filterFlag && !emptyCustomerSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                                    </Col>}
                                                                    {
                                                                        !emptyCustomerSearch && showFilterBox &&
                                                                        <SummaryFilterBox GetTableData={GetTableData} pageNo={pageNo} pageSize={pageSize} searchValueTable={searchValueTable}
                                                                            statusData={statusData}
                                                                            serviceData={serviceData} planName={planName} setPlanName={setPlanName} serviceId={serviceId} setServiceId={setServiceId}
                                                                            customerCode={customerCode} customerData={customerData} setPageNo={setPageNo} sortCol={sortCol} sortDir={sortDir}
                                                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} status={status} setStatus={setStatus}
                                                                            statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed}
                                                                            setScrollToDivTop={setScrollToDivTop} setTableFilterApplied={setTableFilterApplied}

                                                                        />
                                                                    }
                                                                </Row>
                                                            </Col>
                                                            {!serverErrorCustomer &&
                                                                <>
                                                                    <Col lg={2} md={3}>
                                                                        <Form.Group>
                                                                            <NavDropdown
                                                                                className="servcies-dropdown dropdown-specialclass"
                                                                                title={selectedItem2}
                                                                                id="cloud-service-dropdown"
                                                                                onSelect={createHandleSelect(setSelectedItem2)}
                                                                                value={selectedItem2}
                                                                            >
                                                                                <NavDropdown.Item eventKey="Last 3 Months"
                                                                                    className={selectedItem2 === "Last 3 Months" ? "selected" : ""}
                                                                                >Last 3 Months</NavDropdown.Item>
                                                                                <NavDropdown.Item eventKey="Last 6 Months"
                                                                                    className={selectedItem2 === "Last 6 Months" ? "selected" : ""}
                                                                                >Last 6 Months</NavDropdown.Item>
                                                                                <NavDropdown.Item eventKey="Last 1 Year"
                                                                                    className={selectedItem2 === "Last 1 Year" ? "selected" : ""}
                                                                                >Last 1 Year</NavDropdown.Item> </NavDropdown>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={2} md={3}>
                                                                        <Form.Group>
                                                                            <NavDropdown
                                                                                className="servcies-dropdown dropdown-specialclass-fortwo"
                                                                                title={selectedItem3}
                                                                                id="cloud-service-dropdown"
                                                                                onSelect={handleSelectService}
                                                                                value={selectedItem3}
                                                                            >
                                                                                <NavDropdown.Item eventKey="Microsoft CSP"
                                                                                    className={selectedItem3 === "Microsoft CSP" ? "selected" : ""}
                                                                                >Microsoft CSP</NavDropdown.Item>
                                                                                <NavDropdown.Item eventKey="Google Workspace"
                                                                                    className={selectedItem3 === "Google Workspace" ? "selected" : ""}
                                                                                >Google Workspace</NavDropdown.Item>  </NavDropdown>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                            }
                                                        </Row>
                                                        <div className={`mb-1 p-0 ${styles['user-management-table-inner']} ${styles['table-details']}`}>
                                                            <Row>
                                                                <Col>
                                                                    <div className={`table-responsive ${styles['customer-table']}`}>
                                                                        {initialLoading && serverErrorCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Matching Subscription Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerFilters && !emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Subscription Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Subscription Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !serverErrorCustomer && !emptyCustomerFilters && !emptyCustomer && !emptyCustomerSearch &&
                                                                            <LazyTable
                                                                                d={columns}
                                                                                table={table}
                                                                                customStyles={customStyles}
                                                                                loading={infinityLoading}
                                                                                pageNo={pageNo}
                                                                                totalRows={totalRows}
                                                                                handlePerRowsChange={handlePerRowsChange}
                                                                                handlePageChange={handlePageChange}
                                                                                styles={styles}
                                                                                handleLazyCalls={handleLazyCalls}
                                                                                serverErrorUser={serverErrorCustomer}
                                                                                emptyUser={emptyCustomer}
                                                                                emptyUserFilters={emptyCustomerFilters}
                                                                                emptyUserSearch={emptyCustomerSearch}
                                                                                setScrollToDivTop={setScrollToDivTop}
                                                                                scrollToDivTop={scrollToDivTop}
                                                                            />}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="btn-wrapper text-right">
                                                            <Button
                                                                className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                variant="light"
                                                                onClick={() => {
                                                                    close();
                                                                }}
                                                            >
                                                                Close
                                                            </Button>
                                                            {!isDisabledManage && <Button
                                                                className="px-4 mx-2"
                                                                onClick={() => {
                                                                    navigate('/management-console', { state: { searchData: customerName } })
                                                                }}
                                                            >Manage Subscriptions</Button>}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Tab.Container>

                                </Container>
                            </div>

                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default Summary