import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const GCPFilterBox = (props) => {
 
    const [selectedStatuses, setSelectedStatuses] = useState([]);

    // Function to update selected statuses
    const handleStatusChange = (id) => {
        setSelectedStatuses((prevSelectedStatuses) => {
            if (prevSelectedStatuses.includes(id)) {
                return prevSelectedStatuses.filter((statusId) => statusId !== id);
            } else {
                return [...prevSelectedStatuses, id];
            }
        });
    };

    // Function to handle "All Statuses" checkbox
    const handleAllStatusesChange = (isChecked) => {
        setSelectedStatuses(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    useEffect(() => {
        (props.status != null || props.status != undefined) ? setSelectedStatuses(props.status) : setSelectedStatuses([]);
    }, []);

    return (
        <>
            <div className="filter-box filter-box-single">
                <Container>
                    <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                        <h2>Filters</h2>
                        <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                    </Col>
                    <Row className="mb-3 mobile-filter-row">
                        <Col className="filter-column">
                            <h3 className="mb-3">Status</h3>
                            <div>
                                <div className="filter-options">
                                    <>
                                        {props.statusData && props.statusData.length > 0 && <Form.Check
                                            className="mb-2"
                                            type="checkbox"
                                            id={`status-all`}
                                            label="All Statuses"
                                            checked={(selectedStatuses.length === props.statusData.length)}
                                            onChange={(e) => handleAllStatusesChange(e.target.checked)}
                                        />}
                                        {props.statusData && props.statusData.length > 0 &&
                                            props.statusData.map((statusItem) => {
                                                return (
                                                    <Form.Check
                                                        className="mb-2"
                                                        key={statusItem.id}
                                                        type="checkbox"
                                                        id={`status-${statusItem.id}`}
                                                        label={statusItem.name}
                                                        checked={selectedStatuses.includes(statusItem.id)}
                                                        onChange={() => handleStatusChange(statusItem.id)}
                                                    />
                                                );
                                            })}
                                        {!props.statusData &&
                                            <div>Loading ...</div>}
                                    </>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="actions-row">
                        <Col className="d-flex justify-content-end">
                            {((selectedStatuses.length > 0) || ((props.status && props.status.length > 0)))
                                ? <Button
                                    className="btn btn-lg mx-3 btn-border-light"
                                    variant="light"
                                    onClick={() => {
                                        props.setScrollToDivTop(true);
                                        props.setEmptyUserFilters(false);
                                        setSelectedStatuses([]);
                                        props.setPageNo(1);
                                        props.GetTableData(1, props.pageSize, props.searchValueTable, []);
                                        props.setFilterFlag(false);
                                        props.setStatus();
                                    }}
                                >
                                    Clear
                                </Button> :
                                <Button
                                    className="btn btn-lg"
                                    variant="light"
                                    disabled
                                >
                                    Clear
                                </Button>}
                            {(props.status) ||
                                (
                                    (selectedStatuses.length > 0)
                                    )? (
                                <Button
                                    className="btn btn-lg"
                                    variant="primary"
                                    onClick={() => {
                                        props.setScrollToDivTop(true);
                                        props.setStatus(selectedStatuses);
                                        props.setShowFilterBox(false);
                                        props.setPageNo(1);
                                        props.GetTableData(1, props.pageSize, props.searchValueTable, selectedStatuses,false,true);
                                        props.setFilterFlag(true);
                                        props.setFilterApplied(true);
                                    }}
                                >
                                    Apply
                                </Button>
                            ) : (

                                <Button
                                    className="btn btn-lg ml-3"
                                    variant="light"
                                    disabled
                                >
                                    Apply
                                </Button>
                            )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default GCPFilterBox